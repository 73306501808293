import React from 'react'
import styled, { keyframes } from 'styled-components'

import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import { Link, animateScroll as scroll } from 'react-scroll'

import { Helmet } from 'react-helmet'

import colors from '../assets/styles/colors'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import typographySizes from 'src/assets/styles/typographySizes.js'

import HeaderArrowSVG from 'src/assets/svg/header-arrow.svg'
import Nav from 'src/components/global/Nav.js'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Button from 'src/components/global/Button.js'

import ButtonOrder from 'src/components/global/ButtonOrder.js'
import BgLeaf from 'src/components/global/BgLeaf.js'
import Testimonials from 'src/components/global/Testimonials.js'
import MenuSection from '/src/components/global/MenuSection.js'
import AboutSection from 'src/components/global/AboutSection.js'
import PromotionsSection from 'src/components/global/PromotionsSection.js'
import OrderOnlineSection from 'src/components/global/OrderOnlineSection.js'
import ImgRowSection from 'src/components/global/ImgRowSection.js'
import Newsletter from 'src/components/global/Newsletter/Newsletter.js'
import CompanyEve from 'src/components/global/CompanyEve.js'
import PopUp from 'src/components/global/PopUpImg.js'
import fbImg from 'src/assets/images/fb.jpeg'
import favicon from 'src/assets/images/favicon.png'

import menuPDF from 'src/static/menu.pdf'
import WigiliePdf from 'src/static/PINK_wigilia.pdf'

const Header = styled.header`
  height: 100vh;
  width: 100vw;

  background-color: ${colors.bright};
  position: relative;
  overflow: hidden;
  & > .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${mediaQuery.laptop}) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;
    }
  }
  /* @media (max-height: 700px) { */
  @media (max-width: ${mediaQuery.laptop}) {
    height: auto;
    padding: 120rem 0 50rem;
  }
`

const HeaderContent = styled.div`
  width: calc(58.33% - 50rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`

const MainTitle = styled.h1`
  font-size: ${typographySizes.xl}rem;
  line-height: 0.97;
  max-width: 750rem;
  text-transform: uppercase;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.l}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    max-width: 300rem;
  }
`

const SubTitle = styled.h2`
  font-size: ${typographySizes.m}rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.darkGreen};
  max-width: 520rem;
  line-height: 1.3;
  margin-top: 20rem;

  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.s}rem;
    margin-top: 20rem;
  }
`

const HeaderImgWrapper = styled.div`
  width: 41.66%;
  position: relative;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 20rem;
    align-self: center;
    width: 80%;
    margin-top: 30rem;
  }
`

const MoveUpDown = keyframes`
   0%, 100% {
    bottom: 25rem;
  }
  50% {
    bottom: 50rem;
  }
`

const HeaderArrowLink = styled(Link)`
  position: absolute;
  right: 50%;
  transform: translateX(-50%);
  bottom: 50rem;
  animation: MoveUpDown;
  animation: ${MoveUpDown} 2.5s cubic-bezier(0.65, 0, 0.35, 1) infinite;
  /* width: 50px; */
  z-index: 10;
  @media (max-width: ${mediaQuery.tablet}) {
    right: 0rem;
  }
`

const MouseMoveTop = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 100;
  height: 100vh;
  width: 100vw;
`
const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 12%;
  width: 22%;
`
const InfoBox = styled.div`
  position: absolute;
  border-radius: 9rem;
  background-color: white;
  padding: 30rem;
  bottom: 30rem;
  right: 20rem;
  width: 400rem;
  z-index: 10;
  @media (max-width: ${mediaQuery.laptop}) {
    position: relative;
    width: calc(100% - 90rem);
    left: 15rem;
    right: 15rem;
    bottom: 0;
    margin-top: 20rem;
  }
`
const TemporaryReservation = styled.p`
  font-size: ${typographySizes.s}rem;
  color: ${colors.darkGreen};
  margin-top: 50rem;
  margin-right: 100rem;
  display: inline-block;
  a {
    font-size: inherit;
    text-decoration: none;
    color: inherit;
  }
`
// markup
const IndexPage = props => {
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'pl' }}
        titleTemplate={`Kimidori | WEGAŃSKIE SUSHI W DOSTAWIE`}
        title={`Kimidori | WEGAŃSKIE SUSHI W DOSTAWIE`}
      >
        <meta
          name="description"
          content="wegańskie sushi dla miłośników minimalizmu i prostoty"
        />
        <meta name="image" content="../static/fb.jpeg" />
        <meta property="og:image" content={fbImg} />
        <meta property="og:url" content="kimidori.pl/" />
        <meta
          property="og:title"
          content="`WEGAŃSKIE SUSHI W DOSTAWIE| Kimidori`"
        />

        <meta
          property="og:description"
          content="wegańskie sushi dla miłośników minimalizmu i prostoty"
        />
      </Helmet>
      <PopUp />
      <Nav />

      <Header>
        {/* <InfoBox>
          <p>
            W dniach 29.08 – 02.09 KIMIDORI Green Sushi i Pink Vegan będą
            nieczynne. Przenosimy się do nowej lokalizacji na warszawskie
            Powiśle. Zapraszamy na ul. Browarną 6 lok. 4a już w sobotę 03.09. Do
            zobaczenia.
          </p>
        </InfoBox> */}
        <HeaderArrowLink
          to="entry"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <img src={HeaderArrowSVG} />
        </HeaderArrowLink>
        <BgLeaf />

        <MainWrapper className="header_wrapper">
          <HeaderContent>
            <MainTitle
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              wegańskie sushi pełne umami
            </MainTitle>
            <SubTitle
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Odwiedź naszą zieloną restaurację na warszawskim Powiślu lub zamów
              z dostawą do domu
            </SubTitle>
            <div
              data-sal="slide-up"
              data-sal-delay="700"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              <ButtonOrder mRight>Zamów online</ButtonOrder>
              <Button mRight file={WigiliePdf}>
                Wigilie firmowe
              </Button>
              <TemporaryReservation>
                dokonaj rezerwacji: <a href="tel:22 400 66 33">22 400 66 33</a>
              </TemporaryReservation>
              {/* <Button href={menuPDF}>pobierz menu</Button> */}
            </div>
          </HeaderContent>
          <HeaderImgWrapper>
            <StaticImage
              src="../assets/images/Header_img.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
            <Badge>
              {' '}
              <StaticImage
                src="../assets/images/100.png"
                placeholder="none"
                layout="fullWidth"
                quality="100"
              />{' '}
            </Badge>
          </HeaderImgWrapper>
        </MainWrapper>
      </Header>
      {/* <CompanyEve /> */}
      {/* <ImgTextSection /> */}

      <PromotionsSection />
      <MenuSection />
      <AboutSection />
      <Testimonials />
      <OrderOnlineSection />
      <Newsletter />
      <ImgRowSection />
    </>
  )
}
export const query = graphql`
  query Home {
    file(name: { eq: "Header_img" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default IndexPage
